
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class ProjectViewCheckList extends Vue{
  @Prop() snum: number;
  @Prop() owner: string;
  @Prop() config: any;
  @Prop() private readonly isRefunded: boolean;

  privateInfo = false;
  sensitiveInfo = false;
  political = false;
  comment = '';

  created(){
    this.radioSet();
  }

  radioSet(){
    if (this.config.SIMPLE_SURVEY_CHECKLIST) {
      const {PRIVATE_INFO, SENSITIVE_INFO, POLITICAL, COMMENT} = this.config.SIMPLE_SURVEY_CHECKLIST;
      this.privateInfo = PRIVATE_INFO;
      this.sensitiveInfo = SENSITIVE_INFO;
      this.political = POLITICAL;
      this.comment = COMMENT;
    }
  }

  async checkListActive(){
    const sendData = {
      _id: this.snum,
      privateInfo : this.privateInfo,
      sensitiveInfo : this.sensitiveInfo,
      political : this.political,
      comment : this.comment
    };
    await this.axios.post('/admin/project/checkListSave', sendData);
    await this.$store.dispatch('socketStore/unisurveyAlertUpdate',
      {
        SNUM: this.snum,
        userId: this.owner,
        ALERT_CHECK: false
      });
    this.$emit('reload');
  }
}
