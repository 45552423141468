
import { Component, Ref, Vue } from 'vue-property-decorator';
@Component({})
export default class K8sIngressSpecific extends Vue {
  private loading = false;
  get namespace() {
    return this.$route.params.namespace;
  }

  get ingress() {
    return this.$route.params.ingress;
  }

  async load() {
    console.log(1)
  }

}

