import {Module} from 'vuex';
import {io, Socket} from "socket.io-client";

export interface SocketModule {
  userCount: number;
  questionCount: number;
  projectCount: number;
  projectList: any[];
  socket: Socket | null;
}

const URL = process.env.VUE_APP_PRODUCTION === 'production' ?
  'https://unisurvey.co.kr/unisurvey' : 'https://unisurvey.co.kr/unisurvey';

const socketStore: Module<SocketModule, any> = {
  namespaced: true,
  state: {
    userCount: 0,
    questionCount: 0,
    projectCount: 0,
    socket: null,
    projectList: [],
  },
  actions: {
    setSocket({ state}, payload) {
      const {userId, token} = payload;
      state.socket = io(URL, {
      //state.socket = io('http://localhost:3036/unisurvey', {
        transports: ['websocket'],
        reconnection: true,
        reconnectionDelay: 5000,
        auth: {
          authorization: `Bearer ${token}`
        },
        query: {
          userId
        }
      })

      state.socket.on('join-room', () => ({}));
      state.socket.emit('join-room', { roomId: `unisurvey` })

      state.socket.on('adminNewUserCount', (res) => {
        const { count } = res;
        state.userCount = count;
      });

      state.socket.on('admin-no-reply-question', (res) => {
        const { count } = res;
        state.questionCount = count;
      });

      state.socket.on('waitProjectCheck', (res) => {
        const { data } = res;
        state.projectCount = data.reduce((acc, cur) => {
          const {LIST} = cur;
          return acc += LIST.length;
        }, 0);
        state.projectList = data;
      });

      // state.socket.on('replied-question', (res) => {
      //   console.log('reply', res)
      // })
    },

    emitAdminHeaderInfo({ dispatch }) {
      dispatch('emitAdminNewUser');
      dispatch('emitAdminNoReplyQuestion');
      dispatch('emitAdminWaitProject');
      dispatch('emitRepliedQuestion', 'SHKIM@PMIRNC.COM');
    },

    // 신규 유저
    emitAdminNewUser({ state, rootGetters }) {
      state.socket?.emit('adminNewUserCount', {
        adminId: rootGetters.userId
      });
    },

    // 답변 대기중 문의
    emitAdminNoReplyQuestion({ state }) {
      state.socket?.emit('admin-no-reply-question');
    },

    // 검수대기중 프로젝트
    emitAdminWaitProject({ state }) {
      state.socket?.emit('waitProjectCheck');
    },

    // 회원 확인 필드 업데이트
    emitAdminCheckUser({ state,rootGetters }, userId) {
      state.socket?.emit('adminUserCheck', {
        adminId: rootGetters.userId,
        userId,
      })
    },
    // 문의 응답완료
    emitRepliedQuestion({ state }, userId) {
      state.socket?.emit('replied-question', {
        userId,
      })
    },
    unisurveyAlertUpdate({state}, payload) {
      const {userId, SNUM, ALERT_CHECK} = payload;
      state.socket?.emit('unisurveyAlertUpdate', {
        SNUM,
        userId,
        ALERT_CHECK
      });
    },
    socketDestroy({ state }) {
      state.socket?.close();
    },
  },
  getters: {
    userCount: state => state.userCount,
    questionCount: state => state.questionCount,
    projectCount: state => state.projectCount,
    projectList: state => state.projectList,
  },
};

export default socketStore;
