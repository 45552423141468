
import { Component, Ref, Vue, Prop } from 'vue-property-decorator';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { Pod, BodyItem , PodIP, ItemMetadata } from '@/interface/admin/k8s/pod';
import { PodUsage, Container } from '@/interface/admin/k8s/pod.usage';
@Component({
  components: {
    VueJsonPretty,
  },
})
export default class K8sPodComponent extends Vue {
  @Prop() namespace!: string;
  @Prop() deployment!: string;
  @Prop() metadata!: ItemMetadata;
  @Prop() hostIP!: string;
  @Prop() phase!: string;
  @Prop() podIPs!: PodIP[];
  private loading = false;
  private containers: Container[] = [];
  private modal = false;

  get podName() {
    return this.metadata.name;
  }

  async created() {
    this.containers = await this.getUsage();
  }

  get usage() {
    return this.containers.map(({ usage: { cpu, memory } }) => [ `cpu: ${ cpu }`, `mem: ${ memory }` ])
  }

  async getUsage() {
    try {
      this.loading = true;
      const url = `/admin/k8s/pod/${this.namespace}/${ this.deployment }/${ this.podName }/top`;
      const { data: { containers, metadata } } = await this.axios.get<PodUsage>(url);
      console.log(containers, metadata);
      return containers;
    } finally {
      this.loading = false;
    }
  }

}

