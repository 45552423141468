
import { Component, Ref, Vue } from 'vue-property-decorator';
import { PodOne, Metadata } from '@/interface/admin/k8s/pod.one';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
@Component({
  components: {
    VueJsonPretty,
  },
})
export default class K8sPodSpecific extends Vue {
  private loading = false;
  private toggle = false;
  private metaData: Metadata | null = null;
  //
  get namespace () {
    return this.$route.params.namespace;
  }

  get deployment () {
    return this.$route.params.deployment;
  }

  get pod () {
    return this.$route.params.pod;
  }

  get metaDataString() {
    return this.metaData ? '' : ''
  }

  private async created() {
    this.load();
  }

  private async podInfo() {
    const url = `/admin/k8s/pod/${ this.namespace }/${ this.deployment }/${ this.pod }`;
    const { data: { body: { metadata } } } =await this.axios.get<PodOne>(url);
    return metadata;
  }

  private async load() {
    try {
      this.loading = true;
      this.metaData = await this.podInfo();
    } finally {
      this.loading = false;
    }
  }

}

