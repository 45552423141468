
import { Component, Vue } from 'vue-property-decorator';
import {helpInit, IHelp} from '@/types/board';
import BlogDeleteModal from "@/components/modal/BlogDeleteModal.vue";
import {Blog, blogInit} from "@/types/blog";

@Component({
  components: {
    BlogDeleteModal,
  },
})
export default class AdminBlogView extends Vue {
  blogId = '';
  loading = false;
  blogData: Blog = blogInit();
  thumbnail = '';
  tag = '';

  created() {
    this.blogId = this.$route.params.blogId;
    this.load();
  }

  async load() {
    try {
      const { data } = await this.axios.get(`/admin/board/blog/getBlogContent/${this.blogId}`);
      const { result, returnData } = data;
      if (result) {
        this.blogData = returnData.article;
        if(returnData.article.THUMBNAILFILE.length){
          this.thumbnail=returnData.article.THUMBNAILFILE[0].name
        }
      }
      this.loading = false;
    } catch (e) {
      this.loading = false;
      console.log(e);
    }
  }

  remove(): void {
    this.$bvModal.show('board-remove-modal');
  }
  goEdit(){
    this.$router.push(`/admin/blog-edit/${this.blogId}`);
  }
}
