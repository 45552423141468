
import { Component, Vue } from 'vue-property-decorator';
import * as BOARD from '@/types/board';
import { VueEditor } from 'vue2-editor';
import ImageUpload from '@/components/question/ImageUpload.vue';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';
import QuestionReplyComponent from "@/components/question/QuestionReply.vue";

@Component({
  components: {
    VueEditor,
    ImageUpload,
    QuestionReplyComponent
  },
})
export default class QuestionReply extends Vue {
  listId = '';
  editLevel = -1;
  BOARD = BOARD;
  loading = true;
  boardQuestion:any = BOARD.boardQuestionInit();
  boardQuestionList:any = [];
  boardQuestionLast:any = BOARD.boardQuestionInit();

  toList() {
    return this.$router.push({
      path: `/admin/question`,
      query: this.$route.query,
    })
  }

  toPrev() {
    return this.$router.push({
      path: `/admin/question/${this.listId}`,
    })
  }

  created() {
    const { listId } = this.$route.params as { listId: string };
    const { level } = this.$route.params as { level: string };
    this.editLevel = Number(level);
    this.listId = listId;
  }

  async mounted() {
    await this.load();
  }

  async load() {
    try {
      const { data } = await this.axios.get(`/board/QuestionList/Read/${this.listId}`);
      const { question } = data;
      this.boardQuestion = question[0];
      this.boardQuestionList = question;
      this.boardQuestionLast = question[question.length-1];

      if (this.editLevel >= 0)this.boardQuestion = question[this.editLevel];
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }
}
