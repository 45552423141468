
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Node, Item } from '@/interface/admin/k8s/node';
import { NodeOne, Metadata } from '@/interface/admin/k8s/node.one';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

@Component({
  components: {
    VueJsonPretty,
  },
})
export default class K8sNode extends Vue {
  private loading = false;
  private toggle = false;
  private metaData: Metadata | null = null;

  get node() {
    return this.$route.params.node;
  }

  created() {
    this.load();
  }

  async nodeInfo() {
    const url = `/admin/k8s/nodes/${this.node}`;
    const { data: { body: { metadata } } } = await this.axios.get<NodeOne>(url);
    return metadata;
  }

  private async load() {
    try {
      this.loading = true;
      this.metaData = await this.nodeInfo();
    } finally {
      this.loading = false;
    }
  }
}

