
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Item, Deployment } from '@/interface/admin/k8s/deployment';
import { NamespaceOne, Metadata } from '@/interface/admin/k8s/namespace.one';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
@Component({
  components: {
    VueJsonPretty,
  },
})
export default class K8sDeployment extends Vue {
  private loading = false;
  private items: Item[] = [];
  private metaData: Metadata | null = null;

  get namespace () {
    return this.$route.params.namespace;
  }

  get deployment () {
    return this.$route.params.deployment;
  }

  private async created() {
    this.load();
  }

  private async namespaceInfo() {
    const url = `/admin/k8s/namespace/${ this.namespace }`;
    const { data: { body: { metadata } } } =await this.axios.get<NamespaceOne>(url);
    return metadata;
  }

  private async deploymentList() {
    const url = `/admin/k8s/deployment/${this.namespace}`;
    const { data: { body: { items } } } = await this.axios.get<Deployment>(url);
    return items;
  }

  private async scaleDeployment(replicas: number) {
    try {
      this.loading = true;
      console.log(replicas, typeof replicas)
      const url = `/admin/k8s/deployment/${this.namespace}/${this.deployment}`;
      const { data } = await this.axios.patch(url, { replicas });
      console.log(data);
      this.items = await this.deploymentList();
    } finally {
      this.loading = false;
    }
  }

  private async load() {
    try {
      this.loading = true;
      this.metaData = await this.namespaceInfo();
      this.items = await this.deploymentList();

    } finally {
      this.loading = false;
    }
  }

}

