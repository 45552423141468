import { IUserLogin } from '@/interface/user/user';

const IS_PROD = process.env.NODE_ENV === 'production';
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace USER {
    export function LoginInit(): IUserLogin {
        return {
            userId: IS_PROD ? '' : 'simple-survey',
            userPwd: IS_PROD ? '' : '@xptmxm1234',
        };
    }
}

export const ADMIN_ID = 'SIMPLE-SURVEY';

export const enum ROLE {
    ADMIN = 'ADMIN',
    USER = 'USER',
}
