
import {Component, Vue} from "vue-property-decorator";
import HelpList from "@/components/question/HelpList.vue";
import {Dictionary} from "vue-router/types/router";


@Component({
  components: { HelpList, }
})
export default class QuestionList extends Vue{
  showState = 1;
  showing = false;
  loading = false;
  page = 1;
  totalRow = 0;
  perPage = 10;
  userId = this.$route.params.id;
  helpList = [];
  searchButtonHover = true;
  search = '';
  searchType = 'userId';

  searchOptions=[
    { value: 'userId', text: '회원ID' },
    { value: 'title', text: '제목' },
    { value: 'content', text: '내용' },
    { value: 'category', text: '카테고리' },
  ]

  categoryOptions = [
    { value: '가입/회원정보 문의', text: '가입/회원정보 문의' },
    { value: '설문 진행 문의', text: '설문 진행 문의' },
    { value: '템플릿 문의', text: '템플릿 문의' },
    { value: '결제 문의', text: '결제 문의' },
    { value: '기타/오류', text: '기타/오류' },
  ];

  linkGen(page: number) {
    return this.makeQuery({ page });
  }

  makeQuery(queryObj: { [key: string]: string | number }) {
    const { query: routeQuery } = this.$route;
    const temp = { ...routeQuery, ...queryObj };
    const query = Object.entries(temp).map(entry => entry.join('=')).join('&');

    return `?${query}`;
  }

  changeFilter(filterValue: { [key: string]: string | number }) {
    const query = this.makeQuery({
      page: 1,
      ...filterValue
    })
    this.$router.push(query).catch(() => ({}));
  }

  searchSubmit() {
    this.changeFilter({
      search: this.search,
      searchType: this.searchType,
    })
  }

  created(){
    this.init();
    this.load();
  }

  init() {
    const { page, search, searchType  } = this.$route.query as Dictionary<string>;

    if (page) this.page = +page;
    if (search) this.search = search;
    if (searchType) this.searchType = searchType;
  }

  private async load(){
    this.loading = true;

    try {
      const params = {
        PAGE: this.page,
        SEARCH_WORD: this.search,
        searchType: this.searchType,
      };
      const { data } = await this.axios({
        url: '/board/QuestionList/GetList',
        method: 'GET',
        params,
      });
      const { result, list, count } = data;
      if (result === true) {
        this.helpList = list;
        this.totalRow = count;
      }
    } catch (e) {
      console.error(e);
    }

    this.loading = false;
  }

  questionView(index: number) {
    const list = this.helpList[index];
    const { _id } = list;

    this.$router.push({
      path: `/admin/question/${_id}`,
      query: this.$route.query,
    });
  }

  activeSearchButton() {
    this.searchButtonHover = !this.searchButtonHover;
  }

  get numberOfPages() {
    return Math.ceil(this.totalRow / this.perPage) || 1;
  }
}
