
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Token } from '@/interface/admin/k8s/token';
@Component({})
export default class K8sIndex extends Vue {

  private get k8sDashboardUrl() {
    return `https://kubernetes--kubernetes-d-a629b-15232023-b0653684310c.kr.lb.naverncp.com/#/workloads?namespace=simple-survey`;
  }

  private get kubeOpsViewUrl() {
    return `http://simple-surv-kube-ops-vie-6b0bd-15232019-628a039b874a.kr.lb.naverncp.com`;
  }

  get path() {
    return this.$route.path;
  }

  mounted() {
    this.generateDashboardToken();
  }

  private async generateDashboardToken() {
    if (this.path !== '/admin/k8s') return;
    const url = `/admin/k8s/dashboard/token`;
    const { data: { body: { status: { token } } } } = await this.axios.get<Token>(url);
    await window.navigator.clipboard.writeText(token);
    this.$bvToast.toast('Dashboard Token이 클립보드에 복사되었습니다.', {
      title: 'Dashboard Token',
      variant: 'success',
      solid: true,
    });
  }
}

