
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class ProjectViewQuota extends Vue{
  @Prop() quota: any;
  selected;

  quotaAge(str, age){
    let result = false;
    if(age === 10){
      result = this.quota.AGE_10.includes(str);
    }else{
      result = this.quota.AGE_5.includes(str);
    }
    return result;
  }

  quotaArea(area){
    let result = false;
    this.quota.AREA.forEach((a)=>{
      if(a === area){
        result = true;
      }
    });
    return result;
  }




}
