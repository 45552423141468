
import { Component, Vue } from 'vue-property-decorator';
import {helpInit, IHelp} from '@/types/board';
import HelpDeleteModal from '@/components/modal/HelpDeleteModal.vue';

@Component({
  components: {
    HelpDeleteModal,
  },
})
export default class AdminHelpRead extends Vue {
  helpId = '';
  tabIndex = '';
  loading = false;
  help: IHelp = helpInit();
  tag = '';

  created() {
    this.helpId = this.$route.params.helpId;
    this.tabIndex = this.$route.query.tabIndex;
  }

  async mounted() {
    await this.load();
  }

  async load() {
    try {
      const { data } = await this.axios.get(`/board/admin-select-help/${this.helpId}`);
      const { result, help } = data;
      if (result) {
        this.help = help;
      }
      this.loading = false;
    } catch (e) {
      this.loading = false;
      console.log(e);
    }
  }

  remove(): void {
    this.$bvModal.show('board-remove-modal');
  }
}
