
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class QuotaParticipation extends Vue {
  @Prop() surveyParticipation: {
    ALL: { NAME: string; COUNT: number };
    BAD: { NAME: string; COUNT: number };
    COMPLETE: { NAME: string; COUNT: number };
    DROP: { NAME: string; COUNT: number };
    OVER: { NAME: string; COUNT: number };
    OUT: { NAME: string; COUNT: number };
  };
  snum = '';
  @Prop({ default: 0 }) limitCount: number;
  @Prop({ default: '' }) componentType: string;
  @Prop() projectId: number;
  @Prop() loading: boolean;

  percent(target: number, total: number): string {
    return ((target / total) * 100).toFixed(1) + '%';
  }

  redirectPage(type: string) {
    let url = '';
    switch (type) {
      case 'make':
        url = `/project/make/${this.projectId}`;
        break;
      case 'result':
        url = `/project/result/${this.projectId}`;
        break;
      case 'quota':
        url = `/project/quota/${this.projectId}`;
        break;
    }
    this.$router.push(url);
  }

  get maxCompleteFormat(): string {
    return this.$common.numberFormat(this.limitCount);
  }

  get completePercent(): string {
    const target = this.surveyParticipation.COMPLETE.COUNT;
    return this.percent(target, this.limitCount);
  }

  get outPercent(): string {
    const target = this.surveyParticipation.OUT.COUNT;
    const total = this.surveyParticipation.ALL.COUNT;
    return this.percent(target, total);
  }

  get overPercent(): string {
    const target = this.surveyParticipation.OVER.COUNT;
    const total = this.surveyParticipation.ALL.COUNT;
    return this.percent(target, total);
  }

  get dropPercent(): string {
    const target = this.surveyParticipation.DROP.COUNT;
    const total = this.surveyParticipation.ALL.COUNT;
    return this.percent(target, total);
  }

  get allFormat(): string {
    const target = this.surveyParticipation.ALL.COUNT;
    return this.$common.numberFormat(target);
  }

  get completeFormat(): string {
    const target = this.surveyParticipation.COMPLETE.COUNT;
    return this.$common.numberFormat(target);
  }

  get outFormat(): string {
    const target = this.surveyParticipation.OUT.COUNT;
    return this.$common.numberFormat(target);
  }

  get overFormat(): string {
    const target = this.surveyParticipation.OVER.COUNT;
    return this.$common.numberFormat(target);
  }

  get dropFormat(): string {
    const target = this.surveyParticipation.DROP.COUNT;
    return this.$common.numberFormat(target);
  }
}
