
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {Blog} from "@/types/blog";

@Component ({
  components: {}
})
export default class BlogTable extends Vue{
  @Prop() blogList: Blog;
  @Prop() loading: boolean;

  blogView(blogId: string) {
    this.$emit('view', blogId)
  }
}
