
import {Component, Prop, Vue} from 'vue-property-decorator';
import * as BOARD from '@/types/board';
import { VueEditor } from 'vue2-editor';
import ImageUpload from '@/components/question/ImageUpload.vue';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component({
  components: {
    VueEditor,
    ImageUpload,
  },
})
export default class QuestionReplyComponent extends Vue {
  @Prop() boardQuestion;
  @Prop() boardQuestionList;
  @Prop() boardQuestionLast;
  @Prop() listId: string;
  BOARD = BOARD;
  editLevel = Number(this.$route.params.level);
  loading = true;
  customToolbar: any[] = [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['image', 'code-block'],
  ];
  uploadImages: File[] = [];
  dataUrlList: string[] = [];

  editorHtml = '';

  uploadEditor = false;

  toPrev() {
    this.$emit('toPrev');
  }

  toList() {
    this.$emit('toList');
  }
  getShowEditor(bq, text){
    switch (text){
      case 'content':
        if(this.editLevel !== bq.LEVEL){
          return true;
        }else{
          return false;
        }
      case 'edit':
        if(bq.LEVEL === this.editLevel){
          if(this.editorHtml === '') this.editorHtml = bq.ANSWER;
          return true;
        }else{
          return false;
        }
      case 'reply':
        if(bq.LEVEL === this.editLevel){
          return true;
        }else{
          return false;
        }
      case 'file':
        if(bq.LEVEL === this.editLevel){
          return true;
        }else{
          return false;
        }
    }
  }

  async reply(): Promise<void> {
    try {
      this.loading = true;
      let LEVEL = -1;
      let ANSWER = '';
      if(this.editLevel < 0){
        LEVEL = this.boardQuestionLast.LEVEL;
        ANSWER = this.editorHtml;
      }else{
        LEVEL = this.boardQuestion.LEVEL;
        ANSWER = this.editorHtml;
      }
      const { STATUS, ROOT_ID, USER_ID } = this.boardQuestionLast;
      if (ANSWER.length < 5) {
        this.$common.makeToast(ToastMessage.EMPTY_ANSWER, ToastVariant.DANGER, this.$bvToast);
        return;
      }

      const isFiles = this.dataUrlList.length;
      const replacerANSWER = isFiles ? this.getReplacer(ANSWER) : ANSWER;
      const formData: FormData = new FormData();

      this.uploadImages.forEach((file, fileIdx) => {
        formData.append('fileList', file);
      });

      const sendData = {
        STATUS: '답변완료',
        ANSWER: replacerANSWER,
        LEVEL,
        ROOT_ID,
      };
      console.log(ROOT_ID, 'replace');

      const { data } = await this.axios.put(`/admin/board/Reply/${this.listId}`, sendData);
      const { result } = data;

      if (this.dataUrlList.length) {
        await this.uploadFile(formData);
      }
      this.loading = false;
      await this.$store.dispatch('socketStore/emitRepliedQuestion', this.boardQuestion.USER_ID);
      await this.$store.dispatch('socketStore/emitAdminNoReplyQuestion');
      if (result) this.toPrev();
    } catch (e) {
      console.log(e);
    }
  }

  getReplacer(content: string) {
    if (this.dataUrlList.length) {
      this.dataUrlList.forEach((dataUrl, dataIdx) => {
        content = content.replace(dataUrl, `#ReplaceImage.${dataIdx}`);
      });
    }
    return content;
  }

  async uploadFile(formData: FormData) {
    let questionId = '';
    if(this.editLevel < 0){
      questionId = this.boardQuestionLast._id;
    }else{
      questionId = this.boardQuestion._id;
    }
    const { data } = await this.axios.post(`/board/QuestionReply/Reply/${questionId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async upload() {
    let formData = new FormData();
    const { FILES } = this.boardQuestion;
    formData['FILES'] = FILES;
  }

  private imageAppend(data: { file: File; dataUrl: string }) {
    this.uploadImages.push(data.file);
    this.dataUrlList.push(data.dataUrl);

    if(this.editLevel > 0){
      let bqANSW = this.editorHtml;
      bqANSW += `<p><img alt="문의 답변" src="${data.dataUrl}" style="max-width:360px"></p>`;
      this.editorHtml = bqANSW;
    }else{
      let bqANSW = this.editorHtml;
      bqANSW += `<p><img src="${data.dataUrl}" style="max-width:360px" alt="문의 답변"></p>`;
      this.editorHtml = bqANSW;
    }
  }

  handleImageRemove(dataUrl: string) {
    const idx = this.dataUrlList.indexOf(dataUrl);
    if (idx >= 0) {
      this.dataUrlList.splice(idx, 1);
      this.uploadImages.splice(idx, 1);
    }
  }

  async handleImageAdded(file: File, Editor, cursorLocation, resetUploader) {
    if (!file) return;
    const isImage = this.isImage(file);
    if (isImage) {
      const dataUrl = await this.getDataUrl(file);
      const sendData = {
        file,
        dataUrl,
      };
      this.imageAppend(sendData);
    }
  }

  isImage(file: File): boolean {
    const { type } = file;
    const typeList = ['jpg', 'jpeg', 'png'];

    const [image, imageType] = type.split('/');
    if (image !== 'image' || !typeList.includes(imageType)) {
      this.$common.makeToast(ToastMessage.IMAGE_TYPE, ToastVariant.DANGER, this.$bvToast);
      return false;
    } else {
      return true;
    }
  }

  async getDataUrl(file: File): Promise<string> {
    return await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => resolve(reader.result + '');
    });
  }

  statusColor(status: string): string {
    if ( status === BOARD.QUESTION_STATUS.CHECKING ) return 'warning';
    if ( status === BOARD.QUESTION_STATUS.COMPLETE ) return 'success';
    else return '';
  }
}
