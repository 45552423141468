import { render, staticRenderFns } from "./UserQuestionView.vue?vue&type=template&id=614a03c8&scoped=true"
import script from "./UserQuestionView.vue?vue&type=script&lang=ts"
export * from "./UserQuestionView.vue?vue&type=script&lang=ts"
import style0 from "./UserQuestionView.vue?vue&type=style&index=0&id=614a03c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "614a03c8",
  null
  
)

export default component.exports