export enum ToastMessage {
    DELETE = '삭제되었습니다.',
    COPY = '복사되었습니다.',
    MOVE = '이동되었습니다.',
    CHOICE = '선택되었습니다.',
    SAVE = '저장되었습니다.',
    UPDATE = '변경되었습니다.',

    // 로그인 화면
    EMPTY_ID = '아이디를 입력해 주세요.',
    EMPTY_PASSWORD = '비밀번호를 입력해 주세요.',
    LOGIN_MESSAGE = '로그인 정보를 입력해 주세요.',

    CHANGE_TITLE = '제목이 수정되었습니다.',
    DELETE_MESSAGE = '설문이 삭제되었습니다.',
    EMPTY_COPY_TITLE = '복사할 프로젝트 제목을 입력해주세요.',
    LIMIT_FIXED_TEMPLATE = '템플릿 즐겨찾기는 최대 10개 까지 등록 가능합니다.',
    LIMIT_FIXED_SURVEY = '최대 3개의 조사를 고정할 수 있습니다.',

    // 문항 옵션
    QUESTION_COPY = '문항이 복사되었습니다.',
    QUESTION_ADD = '문항이 추가되었습니다.',
    QUESTION_SAVE = '문항이 저장되었습니다.',
    TEMP_SAVE = '임시 저장되었습니다.',
    QUESTION_DELETE = '문항이 삭제되었습니다.',
    QUESTION_NOT_FIND = '문항을 찾을 수 없습니다.',

    QUESTION_NOT_EDIT = '문항은 편집할 수 없습니다.',
    QUESTION_NOT_DELETE = '문항은 삭제할 수 없습니다.',
    QUESTION_NOT_COPY = '문항은 복사할 수 없습니다.',
    QUESTION_NOT_MOVE = '문항은 이동할 수 없습니다.',

    // 회원가입
    NOT_POLICY_AGREE = '약관에 동의해주세요.',
    NOT_AUTH = '본인 인증이 완료되지 않았습니다.',
    AUTH_SUCCESS = '본인 인증에 성공하였습니다.',
    PASSWORD_CHECK1 = '비밀번호가 일치하지 않습니다. 다시 확인해 주세요.',
    PASSWORD_CHECK2 = '비밀번호가 짧습니다. 8글자 이상 입력해주세요.',
    PASSWORD_CHECK3 = '비밀번호가 너무 깁니다. 20글자 미만으로 입력해주세요.',
    ID_CHECK = '사용가능한 아이디 입니다.',
    ID_CHECK2 = '아이디를 다시 한번 확인해주세요.',
    PHONE_CHECK = '휴대폰 번호를 다시 한번 확인해 주세요.',
    EXISTS_PHONE = '이미 가입된 번호입니다.',
    FIND_ID = '인증번호 입력 후 아이디 찾기가 가능합니다.',
    FIND_PASSWORD = '인증번호 입력 후 비밀번호 찾기가 가능합니다.',
    NOT_EXIST_EMAIL = '존재하지 않은 이메일 입니다.',
    NOT_MATCH_EMAIL = '아이디를 이메일 형식에 맞게 입력해주세요.',
    NOT_MATCH_PHONE = '휴대폰 번호 형식에 맞게 입력해주세요.',
    WRONG_AUTH_NUMBER = '인증번호를 잘못 입력하셨습니다.',
    SEND_MAIL = '입력하신 휴대폰 번호로 인증번호를 발송하였습니다.',
    NOT_FIND_USER = '해당 번호로 가입된 회원이 존재하지 않습니다.',
    NOT_CHOICE_REASON = '탈퇴 사유를 선택해 주세요.',
    NOT_WRITE_REASON = '상세 이유를 작성해 주세요.',

    // 계정설정
    CHECK_PASSWORD = '비밀번호를 다시 확인해 주세요.',
    NOT_MATCH_PASSWORD = '비밀번호가 일치하지 않습니다. 다시 확인해 주세요.',
    SHORT_PASSWORD = '비밀번호가 짧습니다. 8글자 이상 입력해주세요.',
    LONG_PASSWORD = '비밀번호가 너무 깁니다. 20글자 미만으로 입력해주세요.',
    SAME_NICKNAME = '동일한 이름으로 변경하실 수 없습니다.',
    FIGURES_CHECK = '위 사항을 확인해 주세요.',

    NOT_IMAGE = '이미지는 삽입할 수 없습니다.',

    // 문의 등록
    EMPTY_ASK_TYPE = '문의 유형을 선택해 주세요.',
    EMPTY_ASK_TITLE = '문의 제목을 입력해 주세요.',
    EMPTY_ASK_CONTENT = '문의 내용을 입력해 주세요.',
    IMAGE_UPLOAD = '이미지 파일을 업로드 한 후 삽입을 눌러주세요.',
    IMAGE_ONE = '이미지 파일을 1개 선택하여 주세요.',
    IMAGE_TYPE = 'jpg, jpeg, png 만 가능합니다.',
    EMPTY_TITLE = '제목을 입력해주세요.',
    EMPTY_CONTENT = '내용을 입력해주세요.',
    EMAIL_NOT_MATCH = '이메일 주소 형식에 맞게 입력해주세요.',
    ASK_SUCCESS = '1:1 문의를 등록하였습니다.',
    UPDATE_HELP_BOARD = '도움말를 수정하였습니다.',
    WRITE_HELP_BOARD = '도움말를 등록하였습니다.',
    EMPTY_TAG = '태그를 입력해주세요.',
    NOT_BLANK_TAG = '태그에 띄어쓰기를 입력할 수 없습니다.',
    SAME_TAG = '이미 입력된 태그 입니다.',

    //블로그 작성
    WRITE_BLOG = '블로그를 작성하였습니다.',

    // 샘플링 설정
    SAMPLING_UPDATE = '샘플링을 수정하였습니다.',
    SAMPLING_RESPONSE = '설정 오류 응답자 수를 설정해 주세요.',
    SAMPLING_RESPONSE_NOT_MATCH = '설정 오류 응답자 수를 설정해 주세요.',
    SAMPLING_GENDER = '설정 오류 성별을 설정해 주세요.',
    SAMPLING_AGE = '설정 오류 연령을 선택해 주세요.',
    SAMPLING_AGE_TYPE = '설정 오류 연령 단위를 선택해주세요.',
    SAMPLING_AGE_RANGE = '설정 오류 연령 범위를 선택해 주세요.',
    SAMPLING_QUOTA_DETAIL = '설정 오류 할당의 합계를 다시 확인해주세요.',
    SAMPLING_QUOTA_TOTAL = '설정 오류 세부 할당 설정을 선택해 주세요.',
    SAMPLING_LOCATION = '설정 오류 지역 설정을 선택해 주세요.',
    SAMPLING_QUOTA = '할당 설정에서 입력 값의 합을 확인하여 주세요. 빈칸일 경우 0을 넣어주세요',
    SAMPLING_QUOTA_BUTTON = '세부 할당 설정 중 인구비례, 임의, 균등 중 하나를 선택하여 주세요.',
    SAMPLING_QUOTA_MAX = '할당 설정표에 MAX 초과로 입력할 수 없습니다. MAX 초과로 입력하고 싶은 경우 관리자에게 문의하여 주세요.',
    // 프로젝트 생성
    CREATE_PROJECT = '프로젝트가 생성되었습니다.',

    // 에러 메세지
    TEMPLATE_GALLERY_ERROR = '템플릿 갤러리 리스트를 얻어오는것을 실패하였습니다.',

    // 생성 옵션
    LOGO_UPLOAD_SUCCESS = '로고 이미지가 업로드 되었습니다.',

    // 제작 옵션
    BORDER_OPTION_MESSAGE = '테두리 색상, 굵기, 모양을 모두 선택해 주셔야 테두리가 적용됩니다.',
    MIN_NOT_ZERO = '글자 수를 0으로 설정할 수 없습니다.',
    MIN_MAX_NOT_SAME = '최대 글자 수를 최소 글자 수보다 높게 설정해주세요.',
    RESPONSE_COUNT_NOT_ZERO = '응답 개수를 0으로 설정할 수 없습니다.',
    RESPONSE_MIN_MAX_NOT_SAME = '최소 응답 개수는 최대 응답 개수 보다 높을 수 없습니다.',
    RESPONSE_COUNT_MAXIMUM_10 = '응답 개수는 최대 10개까지 생성할 수 있습니다.',
    MAX_COUNT_OVER = '입력된 최대 응답 개수가 생성된 보기 수보다 많습니다.',
    SKIP_NOT_SKIP_MESSAGE = '대상 문항과 보기 입력을 모두 입력해주셔야지 조건이 적용됩니다.',
    CHOICE_EXAMPLE_QUESTION = '보기 가져올 문항을 선택해 주세요.',

    // 상단 메뉴 ProjectTopMenu
    LINK_CONFIRMATION = '링크가 확정된 설문의 경우 수정할 수 없습니다.',

    // 현황 페이지
    AFTER_CREATE_LINK = '참여 링크 생성 후 복사가 가능합니다.',
    LINK_COPY_SUCCESS = '참여 링크 복사하였습니다.',
    LINK_COPY_FAILED = '참여 링크 복사 실패',
    CREATE_LINK = '테스트 링크가 생성되었습니다.',
    DATE_RESET = '데이터 초기화 후 링크가 확정되었습니다.',
    UPDATE_STATUS = '진행 상태가 수정되었습니다.',

    // DATA DOWNLOAD
    CREATE_DATA = 'DATA 생성중입니다. 잠시만 기다려주세요.',
    CREATE_DATA_COMPLETE = 'DATA 생성을 완료하였습니다.',
    SET_BANNER = '배너는 최대 4개까지 설정 가능합니다.',
    NOT_SELECTED_BANNER = '배너가 선택되지 않았습니다. 다시 확인해 주세요.',

    // 결제
    PAYMENT_NOT_CHOICE = '결제 방법을 선택해 주세요.',
    PAYMENT_EMPTY_QUESTION = '등록된 문항이 0개 입니다. 문항을 등록한 후 결제해 주세요.',
    PAYMENT_EMPTY_PRICE = '최종 결제 금액이 0원이므로 결제가 불가능 합니다.',
    PAYMENT_SUCCESS = '결제를 완료하였습니다.',
    PAYMENT_FAILED = '결제가 실패되었습니다.',
    NOT_ACCESS_LIST = '리스트 조사는 결제 페이지에 접속할 수 없습니다.',

    // 어드민 템플릿 등록
    MAKE_TEMPLATE_SUCCESS = '템플릿이 등록되었습니다.',
    MAKE_TEMPLATE_FAILED = '템플릿 등록되지 않았습니다. 다시 확인해 주세요.',
    MAKE_TEMPLATE_EMPTY_CATEGORY = '카테고리를 입력해 주세요.',
    MAKE_TEMPLATE_EMPTY_SURVEY_COUNT = '문항 개수를 입력해 주세요.',
    MAKE_TEMPLATE_TIME = '소요시간을 입력해 주세요.',

    // 어드민 문의 답변
    EMPTY_ANSWER = '답변 내용을 입력해주세요.',
    ANSWER_SUCCESS = '답변이 등록되었습니다.',
    NOT_ACCESS = '권한이 없는 페이지 입니다.',
    INVALID = '잘못된 접근입니다. 다시 시도해주세요.',
}

export enum ToastVariant {
    DANGER = 'danger',
    DEFAULT = 'default',
    SECONDARY = 'secondary',
    INFO = 'info',
    WARNING = 'warning',
    PRIMARY = 'primary',
    SUCCESS = 'success',
}
