import {HELP_CATEGORY, QUESTION_STATUS, QUESTION_TYPE} from "@/types/board";
import {IArrayOption} from "@/interface/common";

export interface Blog {
    _id?: string;
    CATEGORY?: string;
    FILES?: FILES[];
    READ_COUNT?: number;
    REGIST_DATE?: Date;
    REGIST_ID?: string;
    REGIST_NAME?: string;
    TITLE: string;
    isUse: boolean;
    CONTENTS: string;
}

export function blogInit(): Blog {
    return {
        _id: '',
        FILES: [],
        READ_COUNT: 0,
        REGIST_DATE: new Date(),
        REGIST_ID: '',
        REGIST_NAME: '',
        isUse: true,
        TITLE: '',
        CONTENTS: '',
        CATEGORY: '',
    }
}

type FILES = {
    _id: string,
    name: string,
    Location: string
}

export const enum BLOG_CATEGORY {
    RESEARCH_KNOWLEDGE = '리서치지식',
    PLAN_SURVEY = '기획조사',
}

export const BLOG_CATEGORY_OPTION: IArrayOption[] = [
    { text: BLOG_CATEGORY.PLAN_SURVEY, value: BLOG_CATEGORY.PLAN_SURVEY },
    { text: BLOG_CATEGORY.RESEARCH_KNOWLEDGE, value: BLOG_CATEGORY.RESEARCH_KNOWLEDGE },
];